import * as React from "react";
import Navbar from "../nav-bar";
import Footer from "../footer";
import Banner from "../banner";
import { Row, Container, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
const ToBuy = (props) => {
  const {pageContext} = props;
  const {url, Content, Title, ShortDescription, BlogImage } = pageContext;
  return (
    <div className="outer-container">
      <Navbar></Navbar>
      <Container fluid className="p-0 overflow-hidden">
        <Banner
          title= {ShortDescription}
          backgroundClass="default-bg bg-to-buy"
          backgroundImg= {BlogImage.localFile.publicURL}
        ></Banner>
        <Row className="article p-5">
          <Col>
            <ReactMarkdown>{ Content }</ReactMarkdown>
          </Col>
        </Row>
        <Footer></Footer>
      </Container>
    </div>
  );
};

export default ToBuy;